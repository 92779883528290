// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bB_kr";
export var caseStudyBackground__lineColor = "bB_kp";
export var caseStudyHead = "bB_kj";
export var caseStudyHead__imageWrapper = "bB_kk";
export var caseStudyProjectsSection = "bB_ks";
export var caseStudyQuote__bgRing = "bB_km";
export var caseStudyQuote__bottomVideo = "bB_kx";
export var caseStudyVideoReview = "bB_lK";
export var caseStudyVideoReview__bgRing = "bB_lL";
export var caseStudyVideo__ring = "bB_kv";
export var caseStudy__bgDark = "bB_kh";
export var caseStudy__bgLight = "bB_kg";
export var caseStudy__bgLightReverse = "bB_lJ";